<template>
  <v-dialog persistent @keydown.esc="close" v-model="bDialogChangePassword" :width="screenWidth + '%'">
    <v-card class="global-container-dialog">
      <v-btn @click="close" icon class="global-btn-close">
        <v-icon> mdi-close </v-icon>
      </v-btn>

      <v-card-title>
        <div class="content-title-change-password">
          <p class="title-change-password">Cambio de contraseña</p>
        </div>
      </v-card-title>

      <v-card-text>
        <div class="content-text-field">
          <v-text-field v-model="sPassword" class="global-text-field mb-3"
            color="var(--primary-color-border-menu-profile)" background-color="var(--primary-color-menu)"
            prepend-inner-icon="mdi-lock" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'" label="Contraseña actual" persistent-placeholder
            placeholder="••••••••••" outlined @click:append="show1 = !show1"></v-text-field>
          <v-text-field v-model="sNewPassword" class="global-text-field mb-3" color="var(--primary-color-border-input)"
            background-color="var(--primary-color-menu)" prepend-inner-icon="mdi-lock"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'" :type="show2 ? 'text' : 'password'"
            label="Nueva contraseña" persistent-placeholder placeholder="••••••••••" outlined
            @click:append="show2 = !show2"></v-text-field>
          <v-text-field v-model="sConfirmNewPassword" class="global-text-field mb-3"
            color="var(--primary-color-border-input)" background-color="var(--primary-color-menu)"
            prepend-inner-icon="mdi-lock" :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show3 ? 'text' : 'password'" label="Confirmar nueva contraseña" persistent-placeholder
            placeholder="••••••••••" outlined @click:append="show3 = !show3"></v-text-field>
        </div>
      </v-card-text>

      <v-card-text>
        <div class="content-btns-accions">
          <div class="content-btn-second">
            <v-btn @click="close" class="global-btn-neutral"> Cancelar </v-btn>
          </div>

          <v-spacer></v-spacer>
          <div class="content-btn-primary">
            <v-btn class="global-btn-primary" @click="changePassword()" :loading="bLoading">
              Aceptar
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    bDialogChangePassword: Boolean,
  },
  data() {
    return {
      screenWidth: 0,
      bLoading: false,
      show1: false,
      sPassword: "",
      show2: false,
      sNewPassword: "",
      show3: false,
      sConfirmNewPassword: "",
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      // size to range
      if (window.innerWidth < 599) {
        this.screenWidth = 100;
      } else if (window.innerWidth > 600 && window.innerWidth < 959) {
        this.screenWidth = 55;
      } else if (window.innerWidth > 960 && window.innerWidth < 1263) {
        this.screenWidth = 45;
      } else if (window.innerWidth > 1264 && window.innerWidth < 1903) {
        this.screenWidth = 30;
      } else if (window.innerWidth > 1904) {
        this.screenWidth = 20;
      }
    },
    changePassword() {
      this.bLoading = true;

      const payload = {
        sPassword: this.sPassword,
        sNewPassword: this.sNewPassword,
        sConfirmNewPassword: this.sConfirmNewPassword,
      },
        config = {
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
            contentType: "application/x-www-form-urlencoded;charset=utf-8",
          },
        };

      DB.patch(
        `${URI}/api/sp/v1/users/${this.sUserId}/password`,
        payload,
        config
      )
        .then((response) => {
          this.bLoading = false;
          this.close();
          this.Success(response.data.message);
          // this.logOut();
        })
        .catch((error) => {

          this.bLoading = false;

          this.Error(error.response.data);
        });
    },
    logOut() {
      const loading = this.$vs.loading({
        type: "circles",
        color: "#F1D72E",
      });

      const payload = {},
        config = {
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
            contentType: "application/x-www-form-urlencoded;charset=utf-8",
          },
        };

      DB.delete(`${URI}/api/sp/v1/session`, config)
        .then((response) => {
          this.Success(response.data.message);

          setTimeout(() => {
            this.$store.commit("setInformationUser", {
              sUserId: "",
              sToken: "",
              sFullName: "",
              sEmail: "",
              sPhoneNumber: "",
              sRole: "",
              tCreatedAt: "",
              aUserPermissions: null,
              // sUrlImg: [],
            });
            this.$router.go();
          }, 1000);
        })
        .catch((error) => {

          this.Error(error.response.data);
        });
    },
    close() {
      this.sPassword = "";
      this.sNewPassword = "";
      this.sConfirmNewPassword = "";
      this.show1 = false;
      this.show2 = false;
      this.show3 = false;
      this.$emit('setDialogChangePassword')
    },
  },
  computed: {
    sUserId() {
      return this.$store.state.sUserId;
    },
  },
  watch: {
    // dialogDelete() {
    //   if (this.dialogDelete) {
    //     this.title = "sii";
    //   }
    // },
  },
};
</script>
<style scoped>
.content-title-change-password {
  width: 100%;
  word-break: normal;
}

.title-change-password {
  color: var(--primary-color-text) !important;
  font-family: "pop-SemiBold";
  font-size: 25px;
  text-align: center;
}

.content-btns-accions {
  display: flex;
  width: 100% !important;
}

.content-btn-primary {
  width: 100%;
}

.content-btn-second {
  width: 100%;
  margin-right: 15px;
}

/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-btns-accions {
    display: block;
    width: 100% !important;
  }

  .content-btn-second {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 15px;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>